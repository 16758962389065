$(document).ready(function(){
  $('.feedbacksSlider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
        },
      },
    ]
  });
  var hiddenElement = document.getElementById("bayNow");
  var btn = $('.toscroll');

  function handleButtonClick() {
    hiddenElement.scrollIntoView({block: "center", behavior: "smooth"});
  }

  $('.toscroll').each(function() {
   let testBtn = $( this );
    testBtn[0].addEventListener('click', handleButtonClick);
  });
});
